import React from "react"
import tw from "twin.macro"


const DisplayPDF: React.FC<{filePDF: string }> = ({ filePDF }) => {
  const PDF = `${process.env.GATSBY_API_ROOT_URL}/Assets/${filePDF}`

  return (
    <div css={[tw`z-50 w-screen h-screen`]}>
      <iframe 
       src={PDF}   
       frameBorder="0"
       scrolling="auto"
       height="100%"
       width="100%">
      </iframe>
  
    </div>
  )
}

export default DisplayPDF

